import { createStore } from 'vuex'
import {post, get} from '@/http/index'
export default createStore({
  state: {
    imgUrl: 'https://100qiao.oss-cn-hangzhou.aliyuncs.com/website/v1',
    popupExperience: false,
  },
  getters: {
  },
  mutations: {
    mutationsPopupExperience(state, payload) {
      state.popupExperience = payload
    }
  },
  actions: {
    async web_web_form(context, payload) {
      return await post('web/web_form', payload)
    }
  },
  modules: {
  }
})
