
import axios from "axios";

const service = axios.create({
  baseURL: '/api',
  timeout: 60000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
		'token': '',
		'X-Requested-With': 'XMLHttpRequest',
  }
})

service.interceptors.request.use(
  function(config) {
    return config
  },
  function(error) {
    console.log(error)
    return Promise.reject(error)
  }

)

service.interceptors.response.use(
  function(response) {
    console.log(response)
    const dataAxios = response.data
    const code = dataAxios.reset
    console.log(code)
    return dataAxios
  },
  function(error) {
    console.log(error)
    return Promise.reject(error)
  }
)

export function get(url: string, params: object) {
  return new Promise((resolve, reject) => {
    service({
      url,
      data: params,
      method: 'GET'
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
export function post(url: string, params: object) {
  return new Promise((resolve, reject) => {
    service({
      url,
      data: params,
      method: 'POST'
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}