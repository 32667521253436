
import { defineComponent, reactive} from "@vue/runtime-core";
import { useStore } from "vuex";
export default defineComponent({
  setup() {
    interface IForm {
      from:number
      company_name: string
      mobile: string | number 
    }
    interface ITestData {
      company_name: boolean
      mobile: boolean
    }
    const store = useStore()
    const mutationsPopupExperience = () => {
      store.commit('mutationsPopupExperience', false)
    }
    const testData:ITestData = reactive({
      company_name: false,
      mobile: false
    })
    const form:IForm = reactive({
      from: 1,
      company_name: '',
      mobile: ''
    } )
    const submit = () => {
      if(!form.company_name) {
        testData.company_name = true
        return
      } else {
        testData.company_name = false
      }
      if(!form.mobile) {
        testData.mobile = true
        return
      } else {
        testData.mobile = false
      }
      store.dispatch('web_web_form', form)
        .then(res => {
          let {code} = res
          if(code === 1) {
            alert('提交成功')
            mutationsPopupExperience()
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
    return {
      state: store.state,
      mutationsPopupExperience,
      testData,
      form,
      submit
    }
  }
})
