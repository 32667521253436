
import { defineComponent, ref } from 'vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import PopupExperience from "@/components/PopupExperience.vue"
export default defineComponent({
  components: {Header, Footer, PopupExperience},
  setup() {
    const msg = ref('')
    return {
      msg
    }
  },
})
