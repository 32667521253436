
import store from "@/store";
import { defineComponent, ref, watch } from "@vue/runtime-core";
import { useRouter } from "vue-router";


export default defineComponent({
  setup() {
    const router = useRouter()
    const mutationsPopupExperience = function() {
      store.commit('mutationsPopupExperience', true)
    }
    let currentRoutePath = ref('/')
    watch(() => router.currentRoute.value.path, (n, o) => {
      console.log(n)
      currentRoutePath.value = n
      // headerNav.forEach(item => {
        
      // })
    }, {immediate: true})
    return {
      state: store.state,
      currentRoutePath,
      mutationsPopupExperience
    }
  },
  
  
  
  
})

