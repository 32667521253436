
import store from "@/store";
import { defineComponent } from "@vue/runtime-core";
import PopupContact from '@/components/PopupContact.vue'


export default defineComponent({
  components: {
    PopupContact
  },
  setup() {
    return {
      state: store.state
    }
  }
})

