import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import('@/views/ApplyView.vue')
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('@/views/CaseView.vue')
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    component: () => import('@/views/CooperationView.vue')
  },
  {
    path: '/price',
    name: 'price',
    component: () => import('@/views/PriceView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
