<template>
  <div class="fixed zindex-10 flex ai_center jc_center" style="bottom: 20%; right: 20px;" v-if="show">
    <img class="cur w-30 h-30 abs right top zindex-10 icon-cancel-wx" style="transform: translate(50%, -50%);" data-img="./image/cancel.png" :src="state.imgUrl + '/index/cancel.png'" alt="" @click="popupHide">
    <div class="rel" style="width: 320px; height: 360px;">
      <img class="w h" data-img="/index/qrcode.png" :src="state.imgUrl + '/index/qrcode.png'" alt="">
      <!-- <router-link class="abs_x bottom h-60 w text-center flex ai_center jc_center op-0" style="bottom: 20px;" to="tel:18650444463">18650444463</router-link> -->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref} from "@vue/runtime-core";
import { useStore } from "vuex";

export default defineComponent({
  name: 'PopupContact',
  setup() {
    const store = useStore()
    const show = ref(true)
    const popupHide = () => {
      show.value = false
    }
    
    return {
      state: store.state,
      show,
      popupHide,
    }
  },
})
</script>

<style>

</style>